import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    details: {
      name: '',
      phone: '+8801',
    },
    video: {
      file1: null,
      file2: null,
      file3: null,
      file4: null,
      file5: null,
    },
    layout: {
      isMobileMenuVisible: false,
    },
  },
  mutations: {
    updateMobileMenuVisible(state, value) {
      state.layout.isMobileMenuVisible = value;
    },
  },
  actions: {},
  modules: {},
});
